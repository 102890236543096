import React from "react";
import CardList from "./CardList";
//import { robots } from "./Robots";
import SearchBox from "./SearchBox";
import Scroll from "./Scroll";
import "./App.css";

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      robots: [],
      searchField: "",
    };
    console.log("constructor");
  }

  componentDidMount() {
    fetch("https://jsonplaceholder.typicode.com/users")
      .then((response) => response.json())
      .then((users) => this.setState({ robots: users }));
  }

  onSearchChange = (event) => {
    this.setState({ searchField: event.target.value });

    console.log(event.target.value);
  };

  render() {
    const { robots, searchField } = this.state;
    const filteredRobots = robots.filter((robots) => {
      return robots.name.toLowerCase().includes(searchField.toLowerCase());
    });
    if (!robots.length) {
      return <h1>Loading</h1>;
    } else {
      return (
        <div className="tc">
          <h1 className="f1">Robo Friends</h1>
          <SearchBox searchChange={this.onSearchChange} />
          <Scroll>
            <CardList robots={filteredRobots} />;
          </Scroll>
        </div>
      );
    }

    // return !robots.length ? (
    //   <h1>Loading</h1>
    // ) : (
    //   <div className="tc">
    //     <h1 className="f1">Robo Friends</h1>
    //     <SearchBox searchChange={this.onSearchChange} />
    //     <Scroll>
    //       <CardList robots={filteredRobots} />;
    //     </Scroll>
    //   </div>
    // );
  }
}

export default App;
